import React from 'react'
import { graphql, Link } from 'gatsby'
import { css } from 'theme-ui'
import Image from 'gatsby-image'
import styled from '@emotion/styled'

const LodgeImage = styled(Image)`
  max-width: 400px;
  border-radius: 10px;
`

export default function ({ data }: any) {
  return (
    <div
      css={css({
        position: 'fixed',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
      })}
    >
      <p css={css({ fontSize: [2, 3, 4] })}>
        <span css={css({ fontSize: [4, 5] })}>👐&nbsp;</span>什么都没有。。。
      </p>
      <LodgeImage fluid={data.file.childImageSharp.fluid} alt="Not found" />
      <p>
        <Link to="/">返回</Link>
      </p>
    </div>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "static/blog_banners/profileImg20.JPG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
